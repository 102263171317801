<template>
  <div>
    <Header />
    <div class="about-section">
      <div class="container mt-5 text-center">
        <h1>About Us</h1>
        <p>Learn more about PeakMind and our mission.</p>
      </div>
    </div>
    <MeetTheTeam />
    <PeakMindInspiration />
    <CallToAction />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import PeakMindInspiration from '../components/PeakMindInspiration.vue'
import HereForYou from '../components/HereForYou.vue'
import MeetTheTeam from '../components/MeetTheTeam.vue'
import CallToAction from '../components/CallToAction.vue'



export default {
  name: 'About',
  components: {
    Header,
    MeetTheTeam,
    PeakMindInspiration,
    CallToAction,
    Footer
  }
}
</script>

<style scoped>
.about-section {
  background: url("https://i.imgur.com/HF56KSh.png") no-repeat center center;
  background-size: cover;
  padding: 100px 0; 
  position: relative;
  height: 40vh;
}

.container {
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
  color: white;
}

h1 {
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 20px;
}

p {
  font-size: 22px;
  line-height: 1.6;
}

.about-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.0); 
  z-index: 1;
}

.about-section .container {
  position: relative;
  z-index: 2;
}
</style>
