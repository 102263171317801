import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '../main.js';


import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Features from '../views/Features.vue'
import Resources from '../views/Resources.vue'
import FAQ from '../views/FAQ.vue'
import TestimonialsPage from '../views/Testimonials.vue'
import SignUp from '../views/SignUp.vue'
import Privacy from '../views/Privacy.vue'
import Support from '../views/Support.vue'

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/about', name: 'About', component: About },
  { path: '/features', name: 'Features', component: Features },
  { path: '/resources', name: 'Resources', component: Resources },
  { path: '/faq', name: 'FAQ', component: FAQ },
  { path: '/testimonials', name: 'TestimonialsPage', component: TestimonialsPage },
  { path: '/signup', name: 'SignUp', component: SignUp },
  { path: '/privacy', name: 'Privacy', component: Privacy },
  { path: '/support', name: 'Support', component: Support },

]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes

})

router.beforeEach((to, from, next) => {
  const currentUser = auth.currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const role = to.meta.role;

  if (requiresAuth && !currentUser) {
    next('/login');
  } else if (requiresAuth && role && currentUser) {
    // Fetch user role from Firestore
    const userRole = 'admin'; // Replace with actual role fetching logic
    if (userRole !== role) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router
