<template>
    <div class="features-section py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 mb-4" v-for="(feature, index) in features" :key="index">
            <div class="card feature-card h-100 text-center">
              <div class="card-body">
                <img :src="feature.image" class="card-img-top mb-3" alt="Feature Image">
                <h5 class="card-title">{{ feature.title }}</h5>
                <p class="card-text">{{ feature.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FeaturesSection',
    data() {
      return {
        features: [
          {
            title: 'Wellness Map',
            description: 'Learn coping mechanisms, complete interactive activities, and empower yourself to handle life’s challenges. Experience gamified education through engaging modules that help you navigate your mental health.',
            image: 'https://imgur.com/lEwbQvl.png'
          },
          {
            title: 'Routine Builder',
            description: 'Build healthy habits and custom routines that stick. Set goals, track your progress, and create a healthier lifestyle. Connect routines with your calendar, one habit at a time.',
            image: 'https://imgur.com/BD8Diqz.png'
          },
          
          {
            title: 'Profiles',
            description: 'Understand your strengths and weaknesses within your mental health journey in an intuitive manner. See how you are doing in different areas, identify factors, and take on challenges to help improve your personal health.',
            image: 'https://imgur.com/OxSpeBi.png'
          },
          {
            title: 'Flow Mode',
            description: 'Achieve peak productivity with Flow Mode, designed to keep you focused and stress-free while you work.',
            image: 'https://i.imgur.com/cjF5YWL.png'
          },
          {
            title: 'Journal',
            description: 'Reflect and grow with daily journal prompts that help you unlock deeper self-awareness and emotional clarity.',
            image: 'https://i.imgur.com/cjF5YWL.png'
          },
          {
            title: 'AI Guide',
            description: 'Access instant support with our Sherpa, your personalized knowledge base. It\'s here to answer your questions, guide you through our app, and provide science-backed insights to help you navigate your mental health journey.',
            image: 'https://i.imgur.com/fQNqINP.png'
          }
        ]
      }
    }
  }
  </script>
  
  <style scoped>
  .features-section {
    background-color: #fff; /* Set background to white */
    padding: 5rem 0;
  }
  
  .feature-card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .feature-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .feature-card .card-body {
    padding: 2rem;
  }
  
  .feature-card .card-img-top {
    max-width: 450px;
    margin: auto;
  }
  
  .feature-card .card-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .feature-card .card-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .features-section {
    animation: fadeIn 2s ease-in-out;
  }
  
  .feature-card {
    animation: fadeInUp 1s ease-in-out;
  }
  </style>
  
