import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@fortawesome/fontawesome-free/css/all.min.css';

//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Import Bootstrap and Bootstrap-Vue CSS files
//import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap-vue/dist/bootstrap-vue.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAGXXQS8KcOJy2fCOodNhVOk9_8pVZYy_w",
  authDomain: "peakmind-website.firebaseapp.com",
  projectId: "peakmind-website",
  storageBucket: "peakmind-website.appspot.com",
  messagingSenderId: "868859025092",
  appId: "1:868859025092:web:87077448b7db6b45551b91",
  measurementId: "G-WCRF87HVYB"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const auth = getAuth();
const db = getFirestore(firebaseApp);

const vueApp = createApp(App);

// Make BootstrapVue available throughout your project
//vueApp.use(BootstrapVue);
//vueApp.use(IconsPlugin);

vueApp.use(store).use(router).mount('#app');

// Monitor authentication state
onAuthStateChanged(auth, (user) => {
  if (user) {
    console.log('User is signed in:', user);
    // You can store the user in a global state or do some routing here
  } else {
    console.log('No user is signed in.');
  }
});

export { auth, db };
