<template>
  <div>
    <Header />
    <HeroSection />
    <div class="container">
      <ServicesOverview />
    </div>
    <WhoWeAre />
    <MentalHealthStatistics />
    <CallToAction />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import HeroSection from '../components/HeroSection.vue'
import ServicesOverview from '../components/ServicesOverview.vue'
import Testimonials from '../components/Testimonials.vue'
import CallToAction from '../components/CallToAction.vue'
import Footer from '../components/Footer.vue'
import WhoWeAre from '../components/WhoWeAre.vue'
import MentalHealthStatistics from '../components/MentalHealthStatistics.vue'

export default {
  name: 'Home',
  components: {
    Header,
    HeroSection,
    ServicesOverview,
    WhoWeAre,
    MentalHealthStatistics,
    CallToAction,
    Footer
  }
}
</script>

<style scoped>
.container {
  padding: 0 1rem;
}

@media (max-width: 768px) {
  .container {
    padding: 0 1rem;
  }
}
</style>
