<template>
    <section class="meet-the-team py-5">
      <div class="container">
        <h2 class="text-center mb-5">Meet the Team</h2>
        <div class="row">
          <div class="col-lg-3 col-md-6 mb-4" v-for="(member, index) in teamMembers" :key="index">
            <div class="card text-center">
              <div class="card-img-container">
                <img :src="member.image" class="card-img-top" :alt="member.name">
              </div>
              <div class="card-body">
                <h5 class="card-title">{{ member.name }}</h5>
                <p class="card-text">{{ member.title }}</p>
                <p class="card-text">{{ member.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'MeetTheTeam',
    data() {
      return {
        teamMembers: [
          {
            name: 'Rahul Rao',
            title: 'Chief Executive Officer',
            description: 'Rahul is a computer science and business student at Northeastern University with a passion for bridging technology and entrereneurship. With a strong foundation in product, artifical intelligence, and clinical research, Rahul brings a depth of practical knowledge and innovative insight to every endeavor.',
            image: 'https://media.licdn.com/dms/image/v2/D4E03AQGRK_nbAxa4-A/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1726606731805?e=1733356800&v=beta&t=GxKmvdiC-G5zqSKxlKJZkh-quHKgK2EsMBgvzZexbdo'
          },
          {
            name: 'Trevor Davis-Dressner',
            title: 'Chief Marketing Officer',
            description: 'Trevor is a business student at Northeastern University. He is an entrepreneur with experience in marketing and data analytics. Trevor has previous experience at Wayfair and has started his own car ride accessory brand generating over $50k in revenue and 65k TikTok following.',
            image: 'https://media.licdn.com/dms/image/v2/C4D03AQG6T3XKbGTcKQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1648959866885?e=1732147200&v=beta&t=-QlNRe6PX-2u5KGa2mHLxbXUxqJbl2OqxZ8sTsSwv3w'
          },
          {
            name: 'Raj Jagirdar',
            title: 'Chief Technology Officer',
            description: 'Raj is studying Computer Science and Civil Engineering at Northeastern University. Having a passion for building, Raj has developed complex microservice based applications for several companies and himself. Raj wants to bring his expertise to build our platform safely, securely, and scalably.',
            image: 'https://media.licdn.com/dms/image/v2/D4E03AQGhqDp0bcdAqA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1690312237825?e=1732147200&v=beta&t=1R06-Oln4w7QUH0o9y7hHSX6ID1qpP-GK9xmTbBKgyk'
          },
          {
            name: 'Mikey Halim',
            title: 'Chief Product Officer',
            description: 'Mikey is a computer science student at the University of Washington with a passion for exploring the intersection of business, design, and technology. He has years of experience in entrepeneurship, graphic design, and mobile app development, previously founding and scaling multiple successful brands.',
            image: 'https://media.licdn.com/dms/image/v2/D5603AQHa94HRLbPvAQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1718292769614?e=1732147200&v=beta&t=Djx79tVrS4gGrwBjb32o-GjxuN5PH_6x-73TkEJdigg'
          },
            {
                name: 'James Wilson',
                title: 'Full Stack Software Engineer',
                description: '',
                image: 'https://media.licdn.com/dms/image/v2/D5603AQH303S_fAK3Eg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1696915084603?e=1732147200&v=beta&t=hrz9Xt0HrVe3QHWCEigNy3s0NOedl-qM0sWEiA_zE3s'
            },
            {
                name: 'Sai Sravan Yarlagadda',
                title: 'AI Engineer',
                description: '',
                image: 'https://avatars.githubusercontent.com/u/144404355?v=4'
            },
            {
                name: 'Zakariya Alhabibi',
                title: 'iOS Software Engineer',
                description: '',
                image: 'https://media.licdn.com/dms/image/v2/D5603AQHG9zsQv5niAw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1718226234481?e=1732147200&v=beta&t=9OupI-R3fP5HOqNUXIR5SKS9H-bSjio8Em9YTYlW8ug'

            }
        ]
      }
    }
  }
  </script>
  
  <style scoped>
  .meet-the-team {
    background: url('https://i.imgur.com/VWq00t3.png') no-repeat center center;
    background-size: cover;
    padding: 5rem 0; /* Added padding to give space above and below the section */
  }
  
  .meet-the-team h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: #08174A; 
  }
  
  .card {
    border: none;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.2s;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card-img-container {
    padding-top: 20px; /* Added padding above the image */
  }
  
  .card-img-top {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin: 0 auto 1rem;
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .card-text {
    font-size: 1rem;
    color: #555;
  }
  </style>
  
