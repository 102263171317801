<template>
  <div class="resources-link">
    <h3><a :href="url" target="_blank" rel="noopener noreferrer">{{ title }}</a></h3>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'ResourcesLink',
  props: {
    title: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.resources-link {
  margin-bottom: 1rem; /* Reduced margin-bottom */
  padding: 0.5rem; /* Reduced padding */
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  transition: transform 0.3s ease, background 0.3s ease;
}

.resources-link h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #555;
}

.resources-link a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.resources-link a:hover {
  text-decoration: underline;
  color: #0056b3;
}

.resources-link p {
  font-size: 1rem;
  color: #555;
  margin-top: 0.5rem;
}

.resources-link:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 1);
}
</style>
