<template>
  <div>
    <Header />
    <div class="container mt-5">
      <h2 class="text-center">What Our Users Say</h2>
      <div class="row mt-4">
        <div class="col-md-4" v-for="(testimonial, index) in testimonials" :key="index">
          <div class="testimonial-card">
            <blockquote class="blockquote">
              <p class="mb-0">{{ testimonial.text }}</p>
              <footer class="blockquote-footer">{{ testimonial.author }}</footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'TestimonialsPage',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      testimonials: [
        { text: "Great service and support!", author: "John Doe" },
        { text: "Highly recommend PeakMind!", author: "Jane Smith" },
        { text: "Excellent resources and tools.", author: "Sam Wilson" }
      ]
    }
  }
}
</script>

<style scoped>
.container {
  padding: 0 1rem;
}

h2 {
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 2rem;
  animation: fadeIn 1s ease-in-out;
}

.row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.testimonial-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  animation: slideInUp 1s ease-in-out;
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blockquote {
  margin: 0;
  padding: 0;
  font-style: italic;
  color: #555;
}

.blockquote-footer {
  font-size: 0.875rem;
  color: #777;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .testimonial-card {
    margin: 10px 0;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>

