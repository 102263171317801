<template>
  <div class="container signup-container">
    <form @submit.prevent="handleSignUp">
      <div class="mb-3">
        <label for="name" class="form-label">First Name</label>
        <input type="text" class="form-control" id="first_name" v-model="name" required>
      </div>
      <div class="mb-3">
        <label for="name" class="form-label">Last Name</label>
        <input type="text" class="form-control" id="last_name" v-model="name" required>
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Email Address</label>
        <input type="email" class="form-control" id="email" v-model="email" required>
      </div>
     <button type="submit" class="btn btn-custom">Join Our Community</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'SignUpForm',
  data() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      message: ''
    }
  },
  methods: {
    handleSignUp() {
      // Handle sign up logic, e.g., send data to an API
      console.log("Sign up with", this.name, this.email, this.message);
    }
  }
}
</script>

<style scoped>
.signup-container {
  max-width: 400px;
  margin: auto;
  margin-top: -80px; /* Added this line to significantly reduce the top margin */
}

label {
  font-weight: bold;
}

button {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
}

.btn-custom {
  background-color: #1E416C;
  border-color: #1E416C;
  color: white;
}

.btn-custom:hover {
  background-color: #0A1A4D;
  border-color: #0A1A4D;
  color: white; 
}
</style>
