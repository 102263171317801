<template>
  <div>
    <Header />
    <div class="header-image-section">
      <div class="container mt-5 text-center">
        <h1>Join Our Community!</h1>
        <p>Join our PeakMind community and be part of something transformative. Share your feedback, help shape the future of mental health tools, and grow alongside a like-minded community. Together, we’re building better mental health solutions for all.</p>
      </div>
    </div>
    <div class="signup-form-section">
      <div class="container signup-container text-center">
        <SignUpForm />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import SignUpForm from '../components/SignUpForm.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'SignUp',
  components: {
    Header,
    SignUpForm,
    Footer
  }
}
</script>

<style scoped>
.header-image-section {
  background: url("https://i.imgur.com/HF56KSh.png") no-repeat center center;
  background-size: cover;
  padding: 100px 0;
  position: relative;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.header-image-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.0); 
  z-index: 1;
}

.header-image-section .container {
  position: relative;
  z-index: 2;
}

.signup-form-section {
  background: white;
  padding: 50px 0;
  position: relative;
}

.signup-container {
  margin-top: -80px; /* Added this line to significantly reduce the top margin */
  max-width: 600px;
  margin: auto;
  padding: 0 1rem;
}

h1 {
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 20px;
}

p {
  font-size: 22px;
  line-height: 1.6;
}
</style>
