<template>
  <div>
    <Header />
    <div class="support-header-section">
      <div class="container mt-5 text-center">
        <h1>Support Request</h1>
        <p>If you need assistance, please fill out the form below or contact us at support@peakmind.com, and our support team will get back to you shortly.</p>
      </div>
    </div>
    <div class="container support-container">
      <form class="support-form">
        <div class="form-group">
          <label for="name">Name</label>
          <input type="text" id="name" class="form-control" placeholder="Your Name" />
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" id="email" class="form-control" placeholder="Your Email" />
        </div>
        <div class="form-group">
          <label for="subject">Subject</label>
          <input type="text" id="subject" class="form-control" placeholder="Subject" />
        </div>
        <div class="form-group">
          <label for="message">Message</label>
          <textarea id="message" class="form-control" rows="5" placeholder="Your Message"></textarea>
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </div>
    <CallToAction />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import CallToAction from '../components/CallToAction.vue'

export default {
  name: 'SupportRequest',
  components: {
    Header,
    CallToAction,
    Footer
  }
}
</script>

<style scoped>
.support-header-section {
  background: url("https://i.imgur.com/HF56KSh.png") no-repeat center center;
  background-size: cover;
  padding: 100px 0; 
  position: relative;
  height: 40vh;
}

.support-header-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.0); 
  z-index: 1;
}

.support-header-section .container {
  position: relative;
  z-index: 2;
  color: white;
}

.container {
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
}

h1 {
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 20px;
}

p {
  font-size: 22px;
  line-height: 1.6;
}

.support-container {
  padding: 50px 0;
}

.support-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  padding: 0.75rem;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
}

.btn-primary:hover {
  background-color: #0056b3;
}
</style>

