<template>
  <div class="who-we-are-section">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h2>The Inspiration</h2>
          <p>
            At PeakMind, we’ve observed the gaps in the mental health system. Current solutions are often unengaging, costly, and leave people feeling unsatisfied. We grew tired of seeing people struggle without innovative methods to support their healing. We believe everyone deserves mental health support without any barriers.
          </p>
        </div>
        <div class="col-md-6">
          <h2>We're Here For You</h2>
          <p>
            No matter who you are, you deserve an affordable and engaging mental health solution. Through our gamified education experience and personalization, we connect you to a system specifically designed to support and empower you on your unique mental health journey.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhoWeAre'
}
</script>

<style scoped>
.who-we-are-section {
  background-color: #f8f9fa;
  padding: 4rem 2rem;
  text-align: center;
  margin-top: -80px;
}

.who-we-are-section .container {
  max-width: 1200px;
  margin: auto;
}

.who-we-are-section h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #08174A; 
}

.who-we-are-section p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
}
</style>
